import Pagina from "../estruturais/pagina/Pagina";
import styles from "./Transacao.module.css";
import { useTransacoes } from "../../servicos/transacoes/useTransacoes";
import Tabela, { Coluna } from "../../componentes/tabela/Tabela";
import { valorParaTexto } from "../../utils/Utils";
import { useCategorias } from "../../servicos/categorias/useCategorias";
import { useContas } from "../../servicos/contas/useContas";
import { useEffect, useState } from "react";
import Cadastro from "./Cadastro/Cadastro";
import Accordion from "../../componentes/accordion/Accordion";
import useContasUtils from "../../hooks/useContasUtils";
import useCategoriasUtils from "../../hooks/useCategoriasUtils";
import Informativo from "../estruturais/informativo/Informativo";
import ResumoSuperior, { ResumoDash } from "../estruturais/resumoSuperior/ResumoSuperior";
import Filtros, { FiltrosType } from "./Filtros/Filtros";
import Slider from "../../componentes/slider/Slider";
import Registro from "./Registro/Registro";

const colunas: Coluna[] = [
  { header: "Id", accessor: "id", visible: false },
  { header: "", accessor: "tipo", type: "transaction_type", size: "100px", align: "center" },
  { header: "Situação", accessor: "foi_transacionada", type: "text_icon", size: "80px", align: "center" },
  { header: "Data", accessor: "data", type: "date", size: "120px", align: "center" },
  { header: "Descricao", accessor: "descricao" },
  { header: "Modo", accessor: "tipo_pagamento", size: "140px", align: "center" },
  { header: "Conta", accessor: "conta", type: "account", size: "100px", align: "center" },
  { header: "Categoria", accessor: "categoria", type: "text_icon", size: "100px", align: "center" },
  { header: "Valor", accessor: "valor", type: "currency", align: "right", size: "120px" }
];

export default function Transacao() {
  const [abrirFiltros, setAbrirFiltros] = useState(false);
  const [filtros, setFiltros] = useState<FiltrosType>({});
  const { transacoes, fetchTransacoes } = useTransacoes();
  const { categorias } = useCategorias();
  const { contas } = useContas();
  const { retornarIcone } = useContasUtils();
  const { retornarCor } = useCategoriasUtils();

  const [selecionada, setSelecionada] = useState<any>(null);

  const handleSelecionar = (row: any) => {
    if (row === null) {
      setSelecionada(null);
      return;
    }

    if (selecionada !== null && selecionada?.id === row?.id) {
      setSelecionada(null);
      return;
    }

    const transacaoOriginal = transacoes.find((transacao) => transacao.id === row.id);
    setSelecionada(transacaoOriginal);
  };

  const handleFiltrar = (filtros: FiltrosType) => {
    fetchTransacoes(filtros);
    setFiltros(filtros);
  };

  const handleLimparFiltros = () => {
    setFiltros({});
    setAbrirFiltros(false);
    fetchTransacoes();
  };

  const retornarNomeCategoria = (id: number) => {
    const categoria = categorias.find((categoria) => categoria.id === id);
    return categoria ? categoria.nome : "";
  };

  const retornarIconeCategoria = (id: number) => {
    const categoria = categorias.find((categoria) => categoria.id === id);
    return categoria ? categoria.icone : "";
  };

  const retornarNomeConta = (id: number) => {
    const conta = contas.find((conta) => conta.id === id);
    return conta ? conta.descricao : "";
  };

  const retornarSituacao = (tipo: string, situacao: boolean) => {
    if (tipo === "R") {
      return situacao ? "task_alt|Recebida|#198000" : "schedule|Pendente|#ff9800";
    } else {
      return situacao ? "task_alt|Recebida|#198000" : "schedule|Pendente|#ff9800";
    }
  };

  const retornarValor = (tipo: string, valor: number) => {
    if (tipo === "R") {
      return valor;
    }

    return valor * -1;
  };

  const dadosAgrupadosData = [...transacoes].reduce((acc: any, transacao) => {
    const data = new Date(transacao.data)?.toISOString()?.split("T")[0];

    if (!acc[data]) {
      acc[data] = [];
    }

    acc[data].push(transacao);
    return acc;
  }, {});

  const totalDespesas =
    transacoes.filter((transacao) => transacao.tipo === "D").reduce((acc, transacao) => acc + parseFloat(transacao.valor.toString()), 0) *
    -1;

  const corDespesas = "#df0101";

  const totalReceitas = transacoes
    .filter((transacao) => transacao.tipo === "R")
    .reduce((acc, transacao) => acc + parseFloat(transacao.valor.toString()), 0);

  const corReceitas = "#198000";

  const isMobile = window.innerWidth <= 1024;

  return (
    <Pagina icone="currency_exchange" pagina="Movimentações">
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <div className={styles.cabecalho}>
            <div className={styles.titulo}>Movimentações</div>
            <Cadastro
              transacao={selecionada}
              setTransacao={handleSelecionar}
              abrirFiltros={abrirFiltros}
              setAbrirFiltros={setAbrirFiltros}
            />
            {abrirFiltros && (
              <Slider titulo="Filtros" subtitulo="Movimentações" onClose={() => setAbrirFiltros(false)}>
                <Filtros
                  filtros={filtros}
                  setFiltros={handleFiltrar}
                  clearFiltros={handleLimparFiltros}
                  onClose={() => setAbrirFiltros(false)}
                />
              </Slider>
            )}
          </div>

          <div className={styles.painel}>
            <Informativo>
              <span>Todas as informações apresentadas respeitam os filtros aplicados.</span>
            </Informativo>
            <ResumoSuperior titulo="Resumo financeiro">
              <ResumoDash titulo="Despesas realizadas" cor={corDespesas}>
                {valorParaTexto(totalDespesas, true)}
              </ResumoDash>
              <ResumoDash titulo="Recebidos até o momento" cor={corReceitas}>
                {valorParaTexto(totalReceitas, true)}
              </ResumoDash>
            </ResumoSuperior>
            {dadosAgrupadosData &&
              Object.keys(dadosAgrupadosData).map((data) => {
                const transacoesData = dadosAgrupadosData[data];

                const partesData = data.split("-");
                const dataFormatada = new Date(
                  parseInt(partesData[0]), // ano
                  parseInt(partesData[1]) - 1, // mês (0-indexado)
                  parseInt(partesData[2]) // dia
                );

                const dataLonga = dataFormatada.toLocaleDateString("pt-BR", {
                  weekday: "long",
                  day: "2-digit",
                  month: "2-digit",
                  year: "numeric"
                });

                const dadosOrdenados = [...transacoesData].sort((a, b) => {
                  return new Date(b.data).getTime() - new Date(a.data).getTime();
                });

                const dadosFormatados = [...dadosOrdenados].map((transacao) => {
                  const nomeConta = retornarNomeConta(transacao.conta_id);

                  return {
                    id: transacao.id,
                    tipo: transacao.tipo,
                    foi_transacionada: retornarSituacao(transacao.tipo, transacao.foi_transacionada),
                    data: new Date(transacao.data).toLocaleDateString("pt-BR", { timeZone: "UTC" }),
                    descricao: transacao.descricao,
                    tipo_pagamento: transacao.tipo_pagamento,
                    categoria:
                      retornarIconeCategoria(transacao.categoria_id) +
                      "|" +
                      retornarNomeCategoria(transacao.categoria_id) +
                      "|" +
                      retornarCor(transacao.categoria_id),
                    conta: retornarIcone(transacao.conta_id) + "|" + nomeConta,
                    valor: retornarValor(transacao.tipo, transacao.valor)
                  };
                });

                return (
                  <Accordion nome={dataLonga.replace("-feira", "")} key={data}>
                    {isMobile ? (
                      <div className={styles.lista}>
                        {dadosFormatados.map((transacao) => (
                          <Registro transacao={transacao} key={transacao.id} selecionada={selecionada} onClick={handleSelecionar} />
                        ))}
                      </div>
                    ) : (
                      <Tabela selecionada={selecionada} colunas={colunas} dados={dadosFormatados} onRowClick={handleSelecionar} />
                    )}
                  </Accordion>
                );
              })}
          </div>
        </div>
      </div>
    </Pagina>
  );
}

