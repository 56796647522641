import React, { useState } from "react";
import { parse } from "ofx-js"; // Certifique-se de instalar o ofx-js com `npm install ofx-js`

export default function ImportarOFX() {
  const [transacoes, setTransacoes] = useState<any[]>([]); // Armazenar as transações do arquivo OFX
  const [error, setError] = useState<string | null>(null); // Armazenar mensagens de erro

  // Função para lidar com a seleção do arquivo
  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;

    const reader = new FileReader();
    reader.onload = async (e) => {
      try {
        const fileContent = e.target?.result as string;

        // Parse do arquivo OFX para JSON
        const parsedData = await parse(fileContent);

        // Extrair transações (depende da estrutura OFX - você pode precisar ajustar conforme o banco)
        const transacoesExtraidas = parsedData.OFX.BANKMSGSRSV1.STMTTRNRS.STMTRS.BANKTRANLIST.STMTTRN;

        setTransacoes(transacoesExtraidas);
        setError(null); // Limpa erros, se houver
      } catch (error) {
        setError("Erro ao processar o arquivo OFX. Verifique o formato do arquivo e tente novamente.");
        console.error("Erro ao processar o arquivo OFX:", error);
      }
    };
    reader.readAsText(file);
  };

  return (
    <div style={{ padding: "20px" }}>
      <h2>Importar Arquivo OFX Bancário</h2>

      <input type="file" accept=".ofx" onChange={handleFileChange} />

      {error && <p style={{ color: "red" }}>{error}</p>}

      {transacoes.length > 0 && (
        <div>
          <h3>Transações:</h3>
          <ul>
            {transacoes.map((transacao, index) => (
              <li key={index}>
                <p>Data: {transacao.DTPOSTED}</p>
                <p>Descrição: {transacao.MEMO}</p>
                <p>Valor: {transacao.TRNAMT}</p>
                <p>Tipo: {transacao.TRNTYPE}</p>
                <hr />
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
}

