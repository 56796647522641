import styles from "./Botao.module.css";

type BotaoProps = {
  texto: string;
  onClick: () => void;
  icone?: string;
  iconPosition?: "left" | "right";
  background?: string;
  color?: string;
  enabled?: boolean;
};

export default function Botao({
  texto,
  onClick,
  icone,
  background = "#ffd862",
  color = "#333333",
  enabled = true,
  iconPosition = "left"
}: BotaoProps) {
  const handleClick = () => {
    if (enabled) {
      onClick();
    }
  };

  const corFundo = enabled === true ? background : "#dbdbdb";
  const corTexto = enabled === true ? color : "#333333";

  return (
    <button
      className={styles.botao}
      onClick={handleClick}
      style={{
        background: corFundo,
        color: corTexto,
        borderColor: corFundo,
        cursor: enabled ? "pointer" : "default",
        flexDirection: iconPosition === "right" ? "row-reverse" : "row"
      }}
      disabled={!enabled}
    >
      {icone && <span className={"material-symbols-rounded " + styles.icone}>{icone}</span>}
      {texto && <span className={styles.texto}>{texto.toUpperCase()}</span>}
    </button>
  );
}

