import { useEffect, useState } from "react";
import Modal from "../../../../componentes/modal/Modal";
import styles from "./Cadastro.module.css";
import Botao from "../../../../componentes/botao/Botao";
import TextField from "../../../../componentes/textField/TextField";
import DropDown from "../../../../componentes/dropdown/DropDown";
import { useContas } from "../../../../servicos/contas/useContas";
import { Conta } from "../../../../servicos/contas/typeConta";
import { textoParaValor, valorParaTexto } from "../../../../utils/Utils";
import Slider from "../../../../componentes/slider/Slider";

const cores = [
  "#03a9f4", // Azul claro
  "#4caf50", // Verde
  "#ffc107", // Amarelo
  "#ff9800", // Laranja
  "#ff5722", // Vermelho forte
  "#cc0000", // Vermelho escuro
  "#9e9e9e", // Cinza
  "#607d8b", // Azul acinzentado
  "#8A05BE", // Roxo Nubank
  "#CC092F", // Vermelho Bradesco
  "#FF6900", // Laranja Itaú
  "#EE3124", // Vermelho Santander
  "#FFCC29", // Amarelo Banco do Brasil
  "#005AA7", // Azul Caixa Econômica Federal
  "#FF7A00", // Laranja Banco Inter
  "#0033A0", // Azul BTG Pactual
  "#00A859", // Verde Banco Original
  "#101820" // Preto C6 Bank
];

const bancos = [
  { icone: "", valor: "Banco do Brasil", texto: "Banco do Brasil" },
  { icone: "", valor: "Bradesco", texto: "Bradesco" },
  { icone: "", valor: "Caixa Econômica", texto: "Caixa Econômica" },
  { icone: "", valor: "Itaú", texto: "Itaú" },
  { icone: "", valor: "Nubank", texto: "Nubank" },
  { icone: "", valor: "Santander", texto: "Santander" },
  { icone: "", valor: "XP Investimentos", texto: "XP Investimentos" },
  { icone: "", valor: "Outros", texto: "Outros" }
];

const tipos = [
  { icone: "", valor: "Corrente", texto: "Corrente" },
  { icone: "", valor: "Dinheiro", texto: "Dinheiro" },
  { icone: "", valor: "Poupança", texto: "Poupança" },
  { icone: "", valor: "Investimento", texto: "Investimento" },
  { icone: "", valor: "Outros", texto: "Outros" }
];

type CadastroProps = {
  conta: Conta | null;
  setConta: (conta: Conta | null) => void;
};

export default function Cadastro({ conta, setConta }: CadastroProps) {
  const [modal, setModal] = useState(false);
  const [dados, setDados] = useState({ saldo: "", saldoProvisorio: "", instituicao: "", descricao: "", tipo: "", cor: "" });
  const { createConta, updateConta } = useContas();

  const todosPreenchidos = !!(dados.instituicao && dados.descricao && dados.tipo && dados.cor);
  const edicao = conta !== null;

  const handleSalvar = () => {
    if (!todosPreenchidos) return;

    const contaAtualizada = {
      saldo: textoParaValor(dados.saldo),
      saldoProvisorio: textoParaValor(dados.saldoProvisorio),
      instituicao: dados.instituicao,
      descricao: dados.descricao,
      tipo: dados.tipo as "Corrente" | "Poupança" | "Investimento" | "Outro",
      cor_identificacao: dados.cor
    };

    if (conta && conta.id !== undefined) {
      updateConta(conta.id, contaAtualizada);
    } else {
      createConta(contaAtualizada);
    }

    setModal(false);
  };

  const limparDados = () => {
    setDados({ saldo: "", saldoProvisorio: "", instituicao: "", descricao: "", tipo: "", cor: "" });
    setConta(null);
  };

  useEffect(() => {
    if (conta) {
      setDados({
        saldo: valorParaTexto(conta.saldo),
        saldoProvisorio: valorParaTexto(conta.saldoProvisorio),
        instituicao: conta.instituicao,
        descricao: conta.descricao,
        tipo: conta.tipo,
        cor: conta.cor_identificacao
      });
      setModal(true);
    }
  }, [conta]);

  useEffect(() => {
    if (!modal) {
      limparDados();
    }
  }, [modal]);

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <div className={styles.botaoAdicionar} onClick={() => setModal(true)}>
          <span className="material-symbols-rounded">add</span>
          <span className={styles.textoAcao}>Nova Conta</span>
        </div>
      </div>
      {modal && (
        <Slider titulo="Conta" subtitulo="Cadastro" onClose={() => setModal(false)}>
          <div className={styles.containerModal}>
            <div className={styles.wrapperModal}>
              <div className={styles.modalCampos}>
                <TextField
                  type="currency"
                  label="Saldo"
                  value={dados.saldo}
                  onChange={(valor) => setDados({ ...dados, saldo: valor })}
                  enabled={!edicao}
                  obrigatorio={true}
                />
                <DropDown
                  label="Instituição"
                  opcoes={[...bancos]}
                  valor={dados.instituicao}
                  onChange={(valor) => setDados({ ...dados, instituicao: valor })}
                />
                <TextField
                  type="text"
                  label="Descrição"
                  value={dados.descricao}
                  onChange={(valor) => setDados({ ...dados, descricao: valor })}
                  enabled={true}
                />
                <DropDown
                  label="Tipo de Conta"
                  opcoes={[...tipos]}
                  valor={dados.tipo}
                  onChange={(valor) => setDados({ ...dados, tipo: valor })}
                />
                <div>
                  <div className={styles.modalLabel}>Uma cor para identificar sua conta</div>
                  <div className={styles.modalCores}>
                    {cores.map((cor) => (
                      <div
                        key={cor}
                        className={styles.modalCor}
                        style={{
                          backgroundColor: cor,
                          border: dados.cor === cor ? "2px solid #333333" : "2px solid transparent"
                        }}
                        onClick={() => setDados({ ...dados, cor: cor })}
                      ></div>
                    ))}
                  </div>
                </div>
              </div>
              <div className={styles.modalBotoes}>
                <Botao texto="Salvar" onClick={() => handleSalvar()} enabled={todosPreenchidos} />
              </div>
            </div>
          </div>
        </Slider>
      )}
    </div>
  );
}

