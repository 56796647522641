/* eslint-disable prefer-const */
import { useEffect, useState } from "react";
import Botao from "../../../componentes/botao/Botao";
import TextField from "../../../componentes/textField/TextField";
import styles from "./TransferenciaConta.module.css";
import DropDown from "../../../componentes/dropdown/DropDown";
import { useContas } from "../../../servicos/contas/useContas";
import { useTransacoes } from "../../../servicos/transacoes/useTransacoes";
import { Transacao } from "../../../servicos/transacoes/typeTransacao";
import { textoParaValor } from "../../../utils/Utils";

type TransferenciaContaProps = {
  transacao?: Transacao;
  onClose: () => void;
};

export default function TransferenciaConta({ onClose }: TransferenciaContaProps) {
  const [dados, setDados] = useState<Transacao>({
    valor: 0,
    foi_transacionada: true,
    data: new Date(),
    tipo: "R"
  });

  const { contas } = useContas();
  const { createTransacao } = useTransacoes();

  const handleSalvar = () => {
    if (dados.valor === 0) {
      return;
    }

    if (dados.conta_id === dados.conta_destino_id) {
      return;
    }

    createTransacao({
      ...dados,
      valor: textoParaValor(dados.valor.toString()),
      tipo: "D"
    });

    createTransacao({
      ...dados,
      valor: textoParaValor(dados.valor.toString()),
      conta_id: dados.conta_destino_id,
      tipo: "R"
    });

    onClose();
  };

  useEffect(() => {
    setDados({ ...dados, conta_destino_id: undefined });
  }, [dados.conta_id]);

  const todosPreenchidos = !!(dados.valor && dados.data && dados.descricao && dados.conta_id && dados.conta_destino_id);

  const opcoesConta = contas.map((conta) => {
    return { icone: "", valor: conta.id?.toString() || "", texto: conta.descricao };
  });

  const opcoesContaDestino = contas
    .filter((conta) => conta.id !== dados.conta_id)
    .map((conta) => {
      return { icone: "", valor: conta.id?.toString() || "", texto: conta.descricao };
    });

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <div className={styles.campos}>
          <TextField
            type="currency"
            label="Valor da Transferencia"
            value={dados.valor.toString()}
            onChange={(valor) => setDados({ ...dados, valor: valor })}
            enabled={true}
            obrigatorio={true}
          />
          <TextField type="date" label="Data" value={dados.data} onChange={(data) => setDados({ ...dados, data: data })} enabled={true} />
          <TextField
            type="text"
            label="Descrição"
            value={dados.descricao}
            onChange={(descricao) => setDados({ ...dados, descricao: descricao })}
            enabled={true}
          />
          <DropDown
            label="Conta de Origem"
            opcoes={opcoesConta}
            valor={dados.conta_id?.toString()}
            onChange={(conta) => setDados({ ...dados, conta_id: parseInt(conta) })}
          />
          <DropDown
            label="Conta de Destino"
            opcoes={opcoesContaDestino}
            valor={dados.conta_destino_id?.toString()}
            onChange={(conta) => setDados({ ...dados, conta_destino_id: parseInt(conta) })}
          />
        </div>
        <div className={styles.botoes}>
          <Botao texto="Salvar" onClick={() => handleSalvar()} enabled={todosPreenchidos} />
        </div>
      </div>
    </div>
  );
}

