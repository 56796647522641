import { useState } from "react";
import TextField from "../../componentes/textField/TextField";
import styles from "./Login.module.css";
import Botao from "../../componentes/botao/Botao";
import { useNavigate } from "react-router-dom";
import Logotipo from "../estruturais/menuLateral/logotipo/Logotipo";
import { usePerfis } from "../../servicos/perfis/usePerfil";

export default function Login() {
  const [dados, setDados] = useState({ email: "", senha: "" });

  const { login } = usePerfis();

  const navigate = useNavigate();

  const handleLogin = () => {
    if (dados.email === "") {
      alert("Informe o e-mail");
      return;
    }

    if (dados.senha === "") {
      alert("Informe a senha");
      return;
    }

    login(dados.email, dados.senha);
  };

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <div className={styles.painelEsquerdo}>
          <Logotipo />
          <div className={styles.voltar}>
            <span className={"material-symbols-rounded"}>arrow_back</span>
            <span>VOLTAR</span>
          </div>
          <div className={styles.titulo}>Acesse sua conta</div>
          <div className={styles.formulario}>
            <TextField
              label="E-mail"
              value={dados.email}
              onChange={(email) => setDados({ ...dados, email: email })}
              type="text"
              enabled={true}
            />
            <TextField
              label="Senha"
              value={dados.senha}
              onChange={(senha) => setDados({ ...dados, senha: senha })}
              type="password"
              enabled={true}
            />
            <Botao texto="Entrar" onClick={() => handleLogin()} background="#ffd862" color="#333333" />
            <div className={styles.opcoes}>
              <div className={styles.opcao} onClick={() => navigate("/recuperar-senha")}>
                <span>Esqueci minha senha</span>
              </div>
              <div className={styles.opcao} onClick={() => navigate("/criar-conta")}>
                <span>Criar conta</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

