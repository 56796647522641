import { useState } from "react";
import { useTransacoes } from "../../servicos/transacoes/useTransacoes";
import { valorParaTexto } from "../../utils/Utils";
import Informativo from "../estruturais/informativo/Informativo";
import Pagina from "../estruturais/pagina/Pagina";
import ResumoSuperior, { ResumoDash } from "../estruturais/resumoSuperior/ResumoSuperior";
import styles from "./Dashboard.module.css";
import MinhasTransacoes from "./MinhasTransacoes/MinhasTransacoes";
import TransacoesMensais from "./TransacoesMensais/TransacoesMensais";
import TipoDespesas from "./TransacoesTipo/TipoDespesas";
import TipoReceitas from "./TransacoesTipo/TipoReceitas";
import UltimasTransacoes from "./UltimasTransacoes/UltimasTransacoes";

export default function Dashboard() {
  const { transacoes } = useTransacoes();

  const [dataSelecionada, setDataSelecionada] = useState(new Date());

  //calcula o total de todas as transações, receitas - despesas
  const totalReceitas = transacoes
    .filter((transacao) => transacao.tipo === "R")
    .reduce((acc, transacao) => acc + parseFloat(transacao.valor.toString()), 0);

  const totalDespesas =
    transacoes.filter((transacao) => transacao.tipo === "D").reduce((acc, transacao) => acc + parseFloat(transacao.valor.toString()), 0) *
    -1;

  const totalTransacoes = totalReceitas + totalDespesas;
  const corTransacao = totalTransacoes > 0 ? "#198000" : "#df0101";

  const mesFormatado = dataSelecionada.toLocaleString("pt-BR", { month: "long", year: "numeric" }).replace(" de ", " ");

  const handleMesAnterior = () => {
    const novoMes = new Date(dataSelecionada);
    novoMes.setMonth(novoMes.getMonth() - 1);
    setDataSelecionada(novoMes);
  };

  const handleProximoMes = () => {
    const novoMes = new Date(dataSelecionada);
    novoMes.setMonth(novoMes.getMonth() + 1);
    setDataSelecionada(novoMes);
  };

  const dataInicio = new Date(Date.UTC(dataSelecionada.getFullYear(), dataSelecionada.getMonth(), 1));
  const dataFim = new Date(Date.UTC(dataSelecionada.getFullYear(), dataSelecionada.getMonth() + 1, 0));

  const transacoesFiltradas = transacoes.filter((transacao) => {
    const dataTransacao = new Date(transacao.data);

    const dataTransacaoUTC = Date.UTC(dataTransacao.getFullYear(), dataTransacao.getMonth(), dataTransacao.getDate());
    const dataInicioUTC = Date.UTC(dataInicio.getFullYear(), dataInicio.getMonth(), dataInicio.getDate());
    const dataFimUTC = Date.UTC(dataFim.getFullYear(), dataFim.getMonth(), dataFim.getDate());

    return dataTransacaoUTC >= dataInicioUTC && dataTransacaoUTC <= dataFimUTC;
  });

  console.log(transacoesFiltradas);

  return (
    <Pagina icone="dashboard" pagina="Dashboard">
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <div className={styles.cabecalho}>
            <div className={styles.titulo}>Dashboard</div>
            <div className={styles.meses}>
              <div className={styles.botaoAcao} onClick={handleMesAnterior}>
                <span className="material-symbols-rounded">chevron_left</span>
              </div>
              <span className={styles.textoAcao}>{mesFormatado.charAt(0).toUpperCase() + mesFormatado.slice(1)}</span>
              <div className={styles.botaoAcao} onClick={handleProximoMes}>
                <span className="material-symbols-rounded">chevron_right</span>
              </div>
            </div>
          </div>
          <div className={styles.painel}>
            <Informativo>
              <span>
                Visualize de forma clara a distribuição dos seus gastos e receitas. Obtenha insights sobre suas finanças com gráficos e
                relatórios personalizados.
              </span>
            </Informativo>
            <ResumoSuperior titulo="Total das contas">
              <ResumoDash titulo="Em todas as contas" cor={corTransacao}>
                <div style={{ fontSize: "18px", fontWeight: "bold" }}>{valorParaTexto(totalTransacoes, true)}</div>
              </ResumoDash>
            </ResumoSuperior>
            <div className={styles.graficos}>
              <MinhasTransacoes transacoes={transacoesFiltradas} />
              <UltimasTransacoes transacoes={transacoesFiltradas} />
            </div>
            <div className={styles.graficos}>
              <TransacoesMensais transacoes={transacoesFiltradas} />
            </div>
            <div className={styles.graficos}>
              <TipoDespesas transacoes={transacoesFiltradas} />
              <TipoReceitas transacoes={transacoesFiltradas} />
            </div>
          </div>
        </div>
      </div>
    </Pagina>
  );
}

