import { useState } from "react";
import TextField from "../../../componentes/textField/TextField";
import styles from "./CriarConta.module.css";
import Botao from "../../../componentes/botao/Botao";
import { useNavigate } from "react-router-dom";
import CheckBox from "../../../componentes/checkbox/CheckBox";
import Logotipo from "../../estruturais/menuLateral/logotipo/Logotipo";
import { usePerfis } from "../../../servicos/perfis/usePerfil";

export default function CriarConta() {
  const [dados, setDados] = useState({ email: "", senha: "", senhaRepetida: "", termoAceite: false });

  const { createPerfil } = usePerfis();

  const navigate = useNavigate();

  const handleCriarConta = () => {
    if (dados.email === "") {
      alert("Informe o e-mail");
      return;
    }

    if (dados.senha === "") {
      alert("Informe a senha");
      return;
    }

    if (dados.senhaRepetida === "") {
      alert("Informe a confirmação da senha");
      return;
    }

    if (dados.senha !== dados.senhaRepetida) {
      alert("As senhas não conferem");
      return;
    }

    if (!dados.termoAceite) {
      alert("Você precisa aceitar os termos de serviço");
      return;
    }

    createPerfil({ email: dados.email, senha: dados.senha });
  };

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <div className={styles.painelEsquerdo}>
          <Logotipo />
          <div className={styles.voltar} onClick={() => navigate("/login")}>
            <span className={"material-symbols-rounded"}>arrow_back</span>
            <span>VOLTAR</span>
          </div>
          <div className={styles.titulo}>Cria sua conta</div>
          <div className={styles.formulario}>
            <TextField
              label="Digite seu e-mail"
              value={dados.email}
              onChange={(email) => setDados({ ...dados, email: email })}
              type="text"
              enabled={true}
              autocomplete="off"
            />
            <TextField
              label="Digite sua senha"
              value={dados.senha}
              onChange={(senha) => setDados({ ...dados, senha: senha })}
              type="password"
              enabled={true}
              autocomplete="new-password"
            />
            <TextField
              label="Digite sua senha novamente"
              value={dados.senhaRepetida}
              onChange={(senhaRepetida) => setDados({ ...dados, senhaRepetida: senhaRepetida })}
              type="password"
              enabled={true}
              autocomplete="new-password"
            />
            <div className={styles.termos}>
              <CheckBox
                label=""
                checked={dados.termoAceite}
                setChecked={(termoAceite) => setDados({ ...dados, termoAceite: termoAceite })}
              />
              <span className={styles.textoTermo}>
                Ao clicar em "Continuar", você concorda com os <b>Termos de Serviço</b> e a <b>Política de Privacidade</b> do Finant.
              </span>
            </div>
            <Botao texto="Continuar" onClick={() => handleCriarConta()} background="#ffd862" color="#333333" />
          </div>
        </div>
      </div>
    </div>
  );
}

