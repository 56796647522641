import MenuLateral from "../menuLateral/MenuLateral";
import PainelCentral from "../painelCentral/PainelCentral";
import styles from "./Pagina.module.css";
import Despesa from "../../cadastros/despesa/Despesa";
import Receita from "../../cadastros/receita/Receita";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useContas } from "../../../servicos/contas/useContas";
import { useCategorias } from "../../../servicos/categorias/useCategorias";
import { useTransacoes } from "../../../servicos/transacoes/useTransacoes";
import Slider from "../../../componentes/slider/Slider";
import TransferenciaConta from "../../cadastros/transferenciaConta/TransferenciaConta";

export default function Pagina(props: { icone: string; pagina: string; children: React.ReactNode }) {
  const navigate = useNavigate();
  const urlParams = new URLSearchParams(window.location.search);

  const { contas, fetchContas } = useContas();
  const { categorias, fetchCategorias } = useCategorias();
  const { transacoes, fetchTransacoes } = useTransacoes();

  const fecharModal = () => {
    navigate(window.location.pathname);
  };

  useEffect(() => {
    if (!contas || contas.length === 0) {
      fetchContas();
    }

    if (!categorias || categorias.length === 0) {
      fetchCategorias();
    }

    if (!transacoes || transacoes.length === 0) {
      fetchTransacoes();
    }
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <MenuLateral />
        <div className={styles.conteudo}>
          <PainelCentral>{props.children}</PainelCentral>
        </div>
      </div>
      {urlParams?.get("lancamento") === "despesa" && (
        <Slider titulo="Adicionar despesa" subtitulo="Movimentações" onClose={() => fecharModal()}>
          <Despesa onClose={() => fecharModal()} />
        </Slider>
      )}
      {urlParams?.get("lancamento") === "receita" && (
        <Slider titulo="Adicionar receita" subtitulo="Movimentações" onClose={() => fecharModal()}>
          <Receita onClose={() => fecharModal()} />
        </Slider>
      )}
      {urlParams?.get("lancamento") === "transferencia" && (
        <Slider titulo="Transferência entra contas" subtitulo="Movimentações" onClose={() => fecharModal()}>
          <TransferenciaConta onClose={() => fecharModal()} />
        </Slider>
      )}
    </div>
  );
}

