import Pagina from "../../estruturais/pagina/Pagina";
import styles from "./CartaoCredito.module.css";

export default function CartaoCredito() {
  return (
    <Pagina icone="credit_card" pagina="Cartões de crédito">
      <div></div>
    </Pagina>
  );
}

