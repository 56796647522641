import styles from "./MinhasTransacoes.module.css";
import { Pie } from "react-chartjs-2";
import { Chart, ArcElement, Tooltip, Legend } from "chart.js";
import { Transacao } from "../../../servicos/transacoes/typeTransacao";
import useCategoriasUtils from "../../../hooks/useCategoriasUtils";
import Quadro from "../Quadro/Quadro";

Chart.register(ArcElement, Tooltip, Legend);

type DashboardProps = {
  transacoes: Transacao[];
};

export default function MinhasTransacoes({ transacoes }: DashboardProps) {
  const { retornarCor, retornarDescricao } = useCategoriasUtils();

  // Agrupando transações por categoria
  const transacoesPorCategoria = transacoes.reduce((acc: any, transacao: Transacao) => {
    const categoriaId = transacao.categoria_id || 0;
    acc[categoriaId] = acc[categoriaId]
      ? acc[categoriaId] + parseFloat(transacao.valor.toString())
      : parseFloat(transacao.valor.toString());
    return acc;
  }, {});

  const categorias = Object.keys(transacoesPorCategoria);
  const valores = Object.values(transacoesPorCategoria) as number[];

  // Calcula o total das transações
  const totalTransacoes = valores.reduce((sum, valor) => sum + valor, 0);

  // Gerando as cores e descrições das categorias usando o hook
  const cores = categorias.map((categoriaId) => retornarCor(Number(categoriaId)));
  const descricoes = categorias.map((categoriaId, index) => {
    const descricao = retornarDescricao(Number(categoriaId)) || "Outra";
    const percentual = ((valores[index] / totalTransacoes) * 100).toFixed(2); // Calcula o percentual
    return `${descricao} - ${percentual}%`; // Formata a descrição como "nome_da_categoria - XX%"
  });

  const data = {
    labels: descricoes, // Descrições no formato "nome_da_categoria - XX%"
    datasets: [
      {
        data: valores,
        backgroundColor: cores,
        hoverBackgroundColor: cores,
        cutout: "60%" // Donut
      }
    ]
  };

  const options = {
    plugins: {
      legend: {
        display: false // Remove as legendas
      },
      tooltip: {
        callbacks: {
          label: function (tooltipItem: any) {
            const value = valores[tooltipItem.dataIndex];
            // Formata o valor em formato de moeda (BRL)
            const formattedValue = value.toLocaleString("pt-BR", {
              style: "currency",
              currency: "BRL"
            });
            return `Total: ${formattedValue}`;
          }
        }
      },
      datalabels: {
        display: false // Esconde os datalabels
      }
    }
  };

  const possuiDados = categorias.length > 0;

  return (
    <div className={styles.container}>
      <Quadro titulo="Resumo das movimentações" rota="/movimentacoes">
        {possuiDados ? (
          <div className={styles.wrapper}>
            <div className={styles.grafico}>
              <Pie data={data} options={options} />
            </div>
            <div className={styles.lista}>
              {categorias.map((categoriaId, index) => {
                const descricao = retornarDescricao(Number(categoriaId)) || "Outra";
                const valor = valores[index];
                const percentual = ((valor / totalTransacoes) * 100).toFixed(2); // Calcula o percentual
                return (
                  <div key={categoriaId} className={styles.item}>
                    <div className={styles.categoria}>
                      <div className={styles.categoriaCor} style={{ backgroundColor: cores[index] }}></div>
                      <span>{descricao}</span>
                    </div>
                    <div className={styles.valor}>
                      {valor.toLocaleString("pt-BR", {
                        style: "currency",
                        currency: "BRL"
                      })}
                    </div>
                    <div className={styles.percentual}>{percentual}%</div>
                  </div>
                );
              })}
            </div>
          </div>
        ) : (
          <div className={styles.semDados}>
            <p>Não há dados para exibir.</p>
          </div>
        )}
      </Quadro>
    </div>
  );
}

