/* eslint-disable prefer-const */
import { useEffect, useState } from "react";
import Botao from "../../../componentes/botao/Botao";
import TextField from "../../../componentes/textField/TextField";
import styles from "./Despesa.module.css";
import DropDown from "../../../componentes/dropdown/DropDown";
import { useCategorias } from "../../../servicos/categorias/useCategorias";
import { useContas } from "../../../servicos/contas/useContas";
import CheckBox from "../../../componentes/checkbox/CheckBox";
import { useTransacoes } from "../../../servicos/transacoes/useTransacoes";
import { Transacao } from "../../../servicos/transacoes/typeTransacao";
import { textoParaValor } from "../../../utils/Utils";

type DespesaProps = {
  transacao?: Transacao;
  setTransacao?: (transacao: Transacao | null) => void;
  onClose: () => void;
};

const tipoPagamento = [
  { icone: "payments", valor: "PIX", texto: "PIX" },
  { icone: "attach_money", valor: "Dinheiro", texto: "Dinheiro" },
  { icone: "credit_card", valor: "Cartão de Crédito", texto: "Cartão de Crédito" },
  { icone: "credit_card", valor: "Cartão de Debito", texto: "Cartão de Debito" },
  { icone: "receipt", valor: "Boleto", texto: "Boleto" },
  { icone: "account_balance", valor: "Transferência", texto: "Transferência" },
  { icone: "lunch_dining", valor: "VR", texto: "Vale Refeição" },
  { icone: "restaurant", valor: "VA", texto: "Vale Alimentação" }
];
export default function Despesa({ transacao, setTransacao, onClose }: DespesaProps) {
  const [dados, setDados] = useState<Transacao>({
    valor: 0,
    foi_transacionada: true,
    data: new Date(),
    tipo: "D",
    parcela: 1,
    totalParcelas: 2
  });

  const { categorias } = useCategorias();
  const { contas } = useContas();
  const { createTransacao, updateTransacao } = useTransacoes();

  const handleLimpar = () => {
    setDados({
      id: undefined,
      valor: 0,
      foi_transacionada: true,
      descricao: "",
      data: dados.data,
      tipo_pagamento: dados.tipo_pagamento,
      categoria_id: dados.categoria_id,
      conta_id: dados.conta_id,
      tipo: "D",
      parcela: 1,
      totalParcelas: 2
    });

    if (setTransacao) {
      setTransacao(null);
    }
  };

  const handleSalvar = () => {
    if (dados.valor === 0) {
      return;
    }

    if (transacao) {
      if (transacao.id !== undefined) {
        updateTransacao(transacao.id, {
          ...dados,
          valor: textoParaValor(dados.valor.toString())
        });
      }
    } else {
      createTransacao({
        ...dados,
        valor: textoParaValor(dados.valor.toString())
      });
    }

    handleLimpar();
  };

  const todosPreenchidos = !!(dados.valor && dados.data && dados.descricao && dados.categoria_id && dados.conta_id);

  const opcoesCategoria = categorias
    .filter((categoria) => categoria.tipo === "D")
    .map((categoria) => {
      return { icone: categoria.icone, valor: categoria.id?.toString() || "", texto: categoria.nome };
    });

  const opcoesConta = contas.map((conta) => {
    return { icone: "", valor: conta.id?.toString() || "", texto: conta.descricao };
  });

  useEffect(() => {
    if (!transacao) {
      return;
    }

    setDados({ ...transacao });
  }, [transacao]);

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <div className={styles.campos}>
          <TextField
            type="currency"
            label="Valor da Despesa"
            value={dados.valor.toString()}
            onChange={(valor) => setDados({ ...dados, valor: valor })}
            enabled={true}
            obrigatorio={true}
          />
          <TextField type="date" label="Data" value={dados.data} onChange={(data) => setDados({ ...dados, data: data })} enabled={true} />
          <TextField
            type="text"
            label="Descrição"
            value={dados.descricao}
            onChange={(descricao) => setDados({ ...dados, descricao: descricao })}
            enabled={true}
          />
          <DropDown
            label="Modo de Pagamento"
            opcoes={tipoPagamento}
            valor={dados.tipo_pagamento}
            onChange={(tipo_pagamento) => setDados({ ...dados, tipo_pagamento: tipo_pagamento })}
          />
          <DropDown
            label="Categoria"
            opcoes={opcoesCategoria}
            valor={dados.categoria_id?.toString()}
            onChange={(categoria) => setDados({ ...dados, categoria_id: parseInt(categoria) })}
          />
          <DropDown
            label="Conta"
            opcoes={opcoesConta}
            valor={dados.conta_id?.toString()}
            onChange={(conta) => setDados({ ...dados, conta_id: parseInt(conta) })}
          />
          <CheckBox
            label={"Pagamento realizado?"}
            icon={"check_circle"}
            checked={dados.foi_transacionada}
            setChecked={(foiPaga) => setDados({ ...dados, foi_transacionada: foiPaga })}
          />
          {!transacao && (
            <CheckBox
              label={"Este lançamento será parcelado?"}
              icon={"replay_5"}
              checked={dados.eh_parcelada || false}
              setChecked={(foiParcelada) => setDados({ ...dados, eh_parcelada: foiParcelada })}
              enabled={!transacao}
            />
          )}
          {!transacao && dados.eh_parcelada && (
            <div className={styles.parcelas}>
              <TextField
                type="number"
                label="Parcela"
                value={dados.parcela}
                onChange={(parcela) => setDados({ ...dados, parcela: parseInt(parcela) })}
                enabled={!transacao}
              />
              {!transacao && (
                <TextField
                  type="number"
                  label="De"
                  value={dados.totalParcelas}
                  onChange={(totalParcelas) => setDados({ ...dados, totalParcelas: parseInt(totalParcelas) })}
                  enabled={!transacao}
                />
              )}
            </div>
          )}
        </div>
        <div className={styles.botoes}>
          <Botao texto="Salvar" onClick={() => handleSalvar()} enabled={todosPreenchidos} />
        </div>
      </div>
    </div>
  );
}

