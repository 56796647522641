import { useEffect, useState } from "react";
import Pagina from "../../estruturais/pagina/Pagina";
import styles from "./Conta.module.css";
import { useContas } from "../../../servicos/contas/useContas";
import Cadastro from "./Cadastro/Cadastro";
import Cartao from "./Cartao/Cartao";
import { Conta as TypeConta } from "../../../servicos/contas/typeConta";
import Informativo from "../../estruturais/informativo/Informativo";
import ResumoSuperior, { ResumoDash } from "../../estruturais/resumoSuperior/ResumoSuperior";
import { valorParaTexto } from "../../../utils/Utils";

export default function Conta() {
  const { contas } = useContas();
  const [contaSelecionada, setContaSelecionada] = useState<TypeConta | null>(null);

  const totalSaldo = contas.reduce((acc, conta) => acc + parseFloat(conta.saldo?.toString() || "0"), 0);

  const corSaldo = totalSaldo >= 0 ? "#198000" : "#df0101";

  const totalPrevisto = contas.reduce((acc, conta) => acc + parseFloat(conta.saldoProvisorio?.toString() || "0"), 0);

  const corPrevisto = totalPrevisto >= 0 ? "#198000" : "#df0101";

  return (
    <Pagina icone="assured_workload" pagina="Minhas Contas Bancárias">
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <div className={styles.cabecalho}>
            <div className={styles.titulo}>Contas Bancárias</div>
            <Cadastro conta={contaSelecionada} setConta={setContaSelecionada} />
          </div>
          <Informativo>
            <span>Adicione ou edite suas contas bancárias.</span>
          </Informativo>
          <ResumoSuperior titulo="Resumo das contas">
            <ResumoDash titulo="Saldo Futuro" cor={corPrevisto}>
              {valorParaTexto(totalPrevisto, true)}
            </ResumoDash>
            <ResumoDash titulo="Saldo Total" cor={corSaldo}>
              {valorParaTexto(totalSaldo, true)}
            </ResumoDash>
          </ResumoSuperior>
          <div className={styles.painel}>
            {contas.map((conta) => (
              <Cartao key={conta.id} conta={conta} setConta={setContaSelecionada} />
            ))}
          </div>
        </div>
      </div>
    </Pagina>
  );
}

