import { useEffect, useState } from "react";
import Pagina from "../../estruturais/pagina/Pagina";
import styles from "./Categoria.module.css";
import { useCategorias } from "../../../servicos/categorias/useCategorias";
import Tabela, { Coluna } from "../../../componentes/tabela/Tabela";
import Cadastro from "./Cadastro/Cadastro";
import Accordion from "../../../componentes/accordion/Accordion";
import { Categoria as TipoCategoria } from "../../../servicos/categorias/typeCategoria";
import Informativo from "../../estruturais/informativo/Informativo";

const colunas: Coluna[] = [
  { header: "Id", accessor: "id", visible: false },
  { header: "Nome", accessor: "nome", size: "70%" },
  { header: "Icone", accessor: "icone", type: "icone", align: "center" },
  { header: "Cor", accessor: "cor_identificacao", type: "cor" },
  { header: "Created", accessor: "createdAt", visible: false },
  { header: "Updated", accessor: "updatedAt", visible: false }
];

export default function Categoria() {
  const { categorias } = useCategorias();
  const [selecionada, setSelecionada] = useState<any>(null);

  const handleSelecionar = (row: any) => {
    if (selecionada !== null && selecionada?.id === row?.id) {
      setSelecionada(null);
      return;
    }

    setSelecionada(row);
  };

  const despesas = categorias.filter((categoria: TipoCategoria) => categoria.tipo === "D");
  const receitas = categorias.filter((categoria: TipoCategoria) => categoria.tipo === "R");

  return (
    <Pagina icone="assured_workload" pagina="Categorias">
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <div className={styles.cabecalho}>
            <div className={styles.titulo}>Categorias</div>
            <Cadastro categoria={selecionada} setCategoria={handleSelecionar} />
          </div>
          <Informativo>
            <span>Adicione ou edite suas categorias de despesas e receitas.</span>
          </Informativo>
          <div className={styles.painel}>
            <Accordion nome="Categorias de Despesas">
              <Tabela selecionada={selecionada} colunas={colunas} dados={despesas} onRowClick={handleSelecionar} />
            </Accordion>
            <Accordion nome="Categorias de Receitas">
              <Tabela selecionada={selecionada} colunas={colunas} dados={receitas} onRowClick={handleSelecionar} />
            </Accordion>
          </div>
        </div>
      </div>
    </Pagina>
  );
}

