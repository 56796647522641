import styles from "./Cadastro.module.css";
import { Categoria } from "../../../../servicos/categorias/typeCategoria";
import Modal from "../../../../componentes/modal/Modal";
import TextField from "../../../../componentes/textField/TextField";
import { useEffect, useState } from "react";
import Botao from "../../../../componentes/botao/Botao";
import { useCategorias } from "../../../../servicos/categorias/useCategorias";
import { cores, icones } from "../../../../utils/Listas";
import DropDown from "../../../../componentes/dropdown/DropDown";
import Slider from "../../../../componentes/slider/Slider";

type CadastroProps = {
  categoria: Categoria | null;
  setCategoria: (conta: Categoria | null) => void;
};

export default function Cadastro({ categoria, setCategoria }: CadastroProps) {
  const [modal, setModal] = useState(false);
  const [dados, setDados] = useState({ nome: "", cor: "", icone: "", tipo: "D" });
  const { createCategoria, updateCategoria } = useCategorias();

  const modoEdicao = categoria !== null;
  const todosPreenchidos = !!(dados.nome && dados.cor);

  const handleNovo = () => {
    limparDados();
    setModal(true);
  };

  const handleEditar = () => {
    setModal(true);
  };

  const handleExcluir = () => {
    setCategoria(null);
  };

  const handleSalvar = () => {
    if (!todosPreenchidos) return;

    const contaAtualizada = {
      nome: dados.nome,
      cor_identificacao: dados.cor,
      tipo: dados.tipo,
      icone: dados.icone
    };

    if (categoria && categoria.id !== undefined) {
      updateCategoria(categoria.id, contaAtualizada);
    } else {
      createCategoria(contaAtualizada);
    }

    setModal(false);
  };

  const limparDados = () => {
    setDados({ nome: "", cor: "", icone: "", tipo: "D" });
    setCategoria(null);
  };

  useEffect(() => {
    if (categoria) {
      setDados({
        nome: categoria.nome,
        cor: categoria.cor_identificacao,
        icone: categoria.icone,
        tipo: categoria.tipo
      });
    }
  }, [categoria]);

  useEffect(() => {
    if (!modal) {
      limparDados();
    }
  }, [modal]);

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <div className={styles.botaoAcao} onClick={() => handleNovo()} style={{ background: "#007bff", borderColor: "#007bff" }}>
          <span className="material-symbols-rounded">add</span>
          <span className={styles.textoAcao}>Nova Categoria</span>
        </div>
        {modoEdicao && (
          <div className={styles.acoesEdicao}>
            <div className={styles.botaoAcao} onClick={() => handleEditar()} style={{ background: "#ed9528", borderColor: "#ed9528" }}>
              <span className="material-symbols-rounded">edit</span>
              <span className={styles.textoAcao}>Editar</span>
            </div>
          </div>
        )}
        {modal && (
          <Slider titulo="Categoria" subtitulo="Cadastro" onClose={() => setModal(false)}>
            <div className={styles.containerModal}>
              <div className={styles.wrapperModal}>
                <div className={styles.modalCampos}>
                  <DropDown
                    label="Tipo"
                    valor={dados.tipo}
                    onChange={(valor) => setDados({ ...dados, tipo: valor })}
                    opcoes={[
                      { valor: "D", texto: "Despesa" },
                      { valor: "R", texto: "Receita" }
                    ]}
                  />
                  <TextField
                    type="text"
                    label="Nome"
                    value={dados.nome}
                    onChange={(valor) => setDados({ ...dados, nome: valor })}
                    enabled={true}
                  />
                  <div>
                    <div className={styles.modalLabel}>Uma cor para identificar sua categoria</div>
                    <div className={styles.modalCores}>
                      {cores.map((cor) => (
                        <div
                          key={cor}
                          className={styles.modalCor}
                          style={{
                            backgroundColor: cor,
                            border: dados.cor === cor ? "2px solid #333333" : "2px solid transparent"
                          }}
                          onClick={() => setDados({ ...dados, cor: cor })}
                        ></div>
                      ))}
                    </div>
                  </div>
                  <div>
                    <div className={styles.modalLabel}>Um icone para identificar sua categoria</div>
                    <div className={styles.modalIcones}>
                      {icones.map((icone) => (
                        <span
                          key={icone}
                          className={styles.modalIcone + " material-symbols-rounded"}
                          style={{
                            border: dados.icone === icone ? "2px solid #333333" : "2px solid transparent"
                          }}
                          onClick={() => setDados({ ...dados, icone: icone })}
                        >
                          {icone}
                        </span>
                      ))}
                    </div>
                  </div>
                </div>
                <div className={styles.modalBotoes}>
                  <Botao texto="Salvar" onClick={() => handleSalvar()} enabled={todosPreenchidos} />
                </div>
              </div>
            </div>
          </Slider>
        )}
      </div>
    </div>
  );
}

