import styles from "./UltimasTransacoes.module.css";
import { useTransacoes } from "../../../servicos/transacoes/useTransacoes";
import { Transacao } from "../../../servicos/transacoes/typeTransacao";
import Quadro from "../Quadro/Quadro";
import Registro from "./Registro";
import { useNavigate } from "react-router-dom";

type DashboardProps = {
  transacoes: Transacao[];
};

export default function UltimasTransacoes({ transacoes }: DashboardProps) {
  const navigate = useNavigate();

  const registrosTransacoes = transacoes.slice(0, 5);

  const possuiDados = registrosTransacoes.length > 0;

  return (
    <div className={styles.container}>
      <Quadro titulo="Últimas movimentações" rota="/movimentacoes">
        {possuiDados ? (
          <div className={styles.wrapper}>
            {registrosTransacoes.map((transacao: Transacao, key) => (
              <Registro key={key} transacao={transacao} onClick={() => navigate("/movimentacoes")} selecionada={null} />
            ))}
          </div>
        ) : (
          <div className={styles.semDados}>
            <p>Não há dados para exibir.</p>
          </div>
        )}
      </Quadro>
    </div>
  );
}

