import styles from "./Cadastro.module.css";
import { Transacao } from "../../../servicos/transacoes/typeTransacao";
import { useTransacoes } from "../../../servicos/transacoes/useTransacoes";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import Despesa from "../../cadastros/despesa/Despesa";
import Receita from "../../cadastros/receita/Receita";
import Filtros from "../Filtros/Filtros";
import Slider from "../../../componentes/slider/Slider";

type CadastroProps = {
  transacao: Transacao | null;
  setTransacao: (conta: Transacao | null) => void;
  abrirFiltros: boolean;
  setAbrirFiltros: (abrirFiltros: boolean) => void;
};

export default function Cadastro({ transacao, setTransacao, abrirFiltros, setAbrirFiltros }: CadastroProps) {
  const { deleteTransacao } = useTransacoes();
  const [modalEdicao, setModalEdicao] = useState(false);
  const navigation = useNavigate();

  const handleExcluir = (id: number) => {
    if (id === undefined || id === null || id === 0) return;

    if (!window.confirm("Deseja realmente excluir esta transação?")) return;

    deleteTransacao(id);
    setTransacao(null);
  };

  const handleEditar = () => {
    setModalEdicao(true);
  };

  const handleNovo = (tipo: string) => {
    navigation("?lancamento=" + tipo);
  };

  const handleClose = () => {
    setModalEdicao(false);
    setTransacao(null);
  };

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        {transacao === null && (
          <div
            className={styles.botaoAcao}
            onClick={() => setAbrirFiltros(!abrirFiltros)}
            style={{ background: "#0063de", borderColor: "#0063de" }}
          >
            <span className="material-symbols-rounded">instant_mix</span>
            <span className={styles.textoAcao}>Filtros</span>
          </div>
        )}
        {transacao === null && (
          <div className={styles.botaoAcao} onClick={() => handleNovo("despesa")} style={{ background: "#cc4400", borderColor: "#cc4400" }}>
            <span className="material-symbols-rounded">trending_down</span>
            <span className={styles.textoAcao}>Adicionar despesa</span>
          </div>
        )}
        {transacao === null && (
          <div className={styles.botaoAcao} onClick={() => handleNovo("receita")} style={{ background: "#198000", borderColor: "#198000" }}>
            <span className="material-symbols-rounded">trending_up</span>
            <span className={styles.textoAcao}>Adicionar receita</span>
          </div>
        )}
        {transacao === null && (
          <div
            className={styles.botaoAcao}
            onClick={() => handleNovo("transferencia")}
            style={{ background: "#6c63ff", borderColor: "#6c63ff" }}
          >
            <span className="material-symbols-rounded">swap_horiz</span>
            <span className={styles.textoAcao}>Transferência entre contas</span>
          </div>
        )}
        {transacao && (
          <div className={styles.botaoAcao} onClick={() => handleEditar()} style={{ background: "#ed9528", borderColor: "#ed9528" }}>
            <span className="material-symbols-rounded">edit</span>
            <span className={styles.textoAcao}>Editar</span>
          </div>
        )}
        {transacao && (
          <div
            className={styles.botaoAcao}
            onClick={() => handleExcluir(transacao.id || 0)}
            style={{ background: "#df0101", borderColor: "#df0101" }}
          >
            <span className="material-symbols-rounded">delete</span>
            <span className={styles.textoAcao}>Apagar</span>
          </div>
        )}
      </div>
      {modalEdicao && transacao?.tipo === "D" && (
        <Slider titulo="Despesa" subtitulo="Movimentações" onClose={handleClose}>
          <Despesa transacao={transacao} setTransacao={setTransacao} onClose={handleClose} />
        </Slider>
      )}
      {modalEdicao && transacao?.tipo === "R" && (
        <Slider titulo="Receita" subtitulo="Movimentações" onClose={handleClose}>
          <Receita transacao={transacao} setTransacao={setTransacao} onClose={handleClose} />
        </Slider>
      )}
    </div>
  );
}

