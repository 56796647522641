import styles from "./Cartao.module.css";
import { Conta } from "../../../../servicos/contas/typeConta";
import { retornarImagemBanco, valorParaTexto } from "../../../../utils/Utils";

type CartaoProps = {
  conta: Conta;
  setConta: (conta: Conta | null) => void;
};

export default function Cartao({ conta, setConta }: CartaoProps) {
  const corSaldoAtual = conta.saldo >= 0 ? "#198000" : "#df0101";
  const corSaldoPrevisto = conta.saldoProvisorio >= 0 ? "#198000" : "#df0101";

  const valorSaldo = parseFloat(conta.saldo?.toString());
  const valorSaldoProvisorio = parseFloat(conta.saldoProvisorio?.toString());

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <div className={styles.titulo}>
          <div className={styles.infos}>
            <img className={styles.icone} src={retornarImagemBanco(conta.instituicao)} alt="Bandeira" />
            <div className={styles.descricao} style={{ color: conta.cor_identificacao }}>
              {conta.descricao}
            </div>
          </div>
          <div className={styles.opcoes}>
            <span className={styles.opcao + " material-symbols-rounded"} onClick={() => setConta(conta)}>
              edit_square
            </span>
          </div>
        </div>
        <div className={styles.saldo}>
          <div>Saldo atual</div>
          <div style={{ color: corSaldoAtual }}>{valorParaTexto(valorSaldo, true)}</div>
        </div>
        <div className={styles.saldo}>
          <div>Saldo futuro</div>
          <div style={{ color: corSaldoPrevisto }}>{valorParaTexto(valorSaldoProvisorio, true)}</div>
        </div>
        {/* <div className={styles.acoes} style={{ borderColor: conta.cor_identificacao }}>
          <div className={styles.despesa} onClick={() => handleAcao("despesa")}>
            <span className={styles.opcao + " material-symbols-rounded"}>trending_down</span>
            Adicionar Despesa
          </div>
          <div className={styles.receita} onClick={() => handleAcao("receita")}>
            <span className={styles.opcao + " material-symbols-rounded"}>trending_up</span>
            Adicionar Receita
          </div>
        </div> */}
      </div>
    </div>
  );
}

